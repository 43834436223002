@keyframes glow {
  0%, 100% { box-shadow: 0 0 5px #48bb78; }
  50% { box-shadow: 0 0 20px #48bb78; }
}
.animate-glow {
  animation: glow 2s ease-in-out infinite;
}
.marquee-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.marquee-container::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 25px;
  /* background: linear-gradient(to right, transparent, #1f2937); */
}
.marquee {
  display: inline-block;
  padding-right: 25px;
}
.marquee-container:hover .marquee {
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}